<template>
	
</template>

<script>
	import axios from "axios";
	import { getUUID } from "@/utils";
	import { log } from "util";
	export default {
	  data() {
	    return { 
	    };
	  }, 
	  mounted(){
		  axios.post("/bigdata/third-platform/login", {platform:'xuanmei',code:'sso_admin'})
		     .then((response) => {
		       console.log("大数据系统登录成功");
		       console.log(response);
			   this.getcurrUserInfo(); //前端保存账户数据
			   this.$cookie.set("token", response.data.token);
			   //跳转到大数据主页面 
				this.$router.replace({ path: "/home" }); 
		      
		     })
		     .catch((error) => {
		       console.log(error); 
		       this.$message.error("查询失败");
		     });
	  },
	  methods: {
		//获取当前用户信息
		async getcurrUserInfo() { 
		  await axios.get('/bigdata/sys/user/info', {
		    params: this.$http.adornParams()
		    })
		  .then(({ data }) => {
		    if (data && data.code === 0) {
		      console.log("获取当前用户信息",data.user);
		      window.sessionStorage.setItem("user", JSON.stringify(data.user)); //前端保存账户数据
		    }
		    })
		},
	  },
	};
</script>

<style>
</style>